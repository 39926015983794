import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'phone'
})
export class PhonePipe implements PipeTransform {

    transform(value: any, args?: any): any {
        const numero = value.toString();

        const tel = numero.slice(0, 3) + ' ' + numero.slice(3, 5) + ' ' + numero.slice(5, 8) + ' ' + numero.slice(8, 10);
        return tel;
    }

}
