import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {User} from '../modeles/user';
import {AppService} from './app.service';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthentificationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  constructor(private http: HttpClient, private appService: AppService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUtilisateur')));
    this.currentUser = this.currentUserSubject.asObservable();
  }
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }
  login(username: string, password: string) {
    return this.http.post<any>('utilisateur/authentification', { username, password }).pipe(map(user => {
      console.log(JSON.stringify(user));
      if (user && user.token) {
        localStorage.setItem('nameutilisateur', username);
        localStorage.setItem('nomutilisateur', user.user.nom);
        localStorage.setItem('idutilisateur', user.user.id);
        localStorage.setItem('idprofileemetteur', user.user.profil.id);
        localStorage.setItem('idprofileUtilisateur', user.user.profilutilisateur.id);
        localStorage.setItem('currentUtilisateur', JSON.stringify(user));
        console.log(JSON.stringify(user));
        this.currentUserSubject.next(user);
        this.appService.setUserLoggedIn(true);
        return user;
      }

    }));
  }

  logout() {
    localStorage.removeItem('currentUtilisateur');
    localStorage.removeItem('nomutilisateur');
    this.appService.setUserLoggedIn(false);
    this.currentUserSubject.next(null);
  }
}
